import * as React from 'react';
import { Link } from 'gatsby';

export const Head = () => (
  <>
    <title>404 - Nie znaleziono strony</title>
    <link rel="canonical" href="https://gabinetelzbieta.pl/404/" />
  </>
);

const NotFoundPage = () => (
  <main>
    <div className="row">
      <h1>Błąd 404 - nie znaleziono strony</h1>
      <p>
        Przejdź do
        {' '}
        <Link to="/">Strony głównej</Link>
        .
      </p>
    </div>
  </main>
);

export default NotFoundPage;
